import { MutableRefObject, createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef } from "react";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { SmartState, useSmartState } from "../../../hooks/use_smart_state";
import { StatusMessage, useStatusMessage } from "../../../hooks/use_status_message";
import { misc } from "../../../lib/misc";
import { FormError, FormRequestMakeup, FormRequestMakeupInput, FormValues } from "../../../lib/shared/form-request-makeup/types";
import { RedirectDirective, useIsSecurePage } from "../secure_page";
import { RequestErrorCustom, RequestErrorNoNetworkConnection, RequestErrorPermissionDenied, postRequest } from "../../../lib/server_requests";
import { Account, consumeAccount, useAccount } from "../../../hooks/use_account";
import { useCustomRouter } from "../../../hooks/use_custom_router";
import { preprocessFromMakeup } from "../../../lib/shared/form-request-makeup/preprocessing";
import { useAccountBarParentNav } from "../../account-bar";

export type FormParent = {
    caption: string;
    path: string;
};

export type FormInputCaptionPosition = "header" | "placeholder";

export type FormInput = {
    maxWidth?: number;
    captionPosition?: FormInputCaptionPosition;
};

export type FormOnSuccessResultMessage = {
    type: "message";
    message: StatusMessage; 
};

export type FormOnSuccessResultRedirect = {
    type: "redirect";
} & RedirectDirective;

export type FormOnSuccessResult = FormOnSuccessResultMessage | FormOnSuccessResultRedirect;
export type FormAlignment = "left" | "center" | "right";

export type InitializeCBResult = {
    autoFocus?: true | string;
    inputInitialValues?: {
        [name: string]: string
    };
    message?: string | {
        value: string;
        permanent?: boolean;
    };
};

export type FormProps = {
    parent?: FormParent;
    children?: any;
    input?: FormInput;
    autoFocus?: true | string;
    requestMakeup?: FormRequestMakeup;
    onSuccess?: (result?: unknown,name?: string) => FormOnSuccessResult | void;
    align?: FormAlignment;
    initializeCb?: () => InitializeCBResult | void;
    initialError?: string;
    invisible?: boolean;
    alternateStatusMessage?: ReturnType<typeof useStatusMessage>;
};

class FormInternalData {
    private inputs: FormInputInterfaceTo[] = [];
    private submit: FormSubmitInterfaceTo;
    private namedSubmits: {
        [name: string]: FormSubmitInterfaceTo;
    } = {};
    private valuesInitialized = false;

    constructor(private formStateValue: FormState,private formRequestMakeup: FormRequestMakeup) {}

    getFirstNonHiddenInput() {
        return this.inputs.filter(({getIsHidden}) => !getIsHidden())[0];
    }

    getInputByName(name: string) {
        return this.inputs.filter((interfaceTo) => interfaceTo.getName()===name)[0];
    }

    getInputHashByName() {
        const ret: {[name: string]: FormInputInterfaceTo} = {};
        this.inputs.forEach((interfaceTo) => {
            ret[interfaceTo.getName()] = interfaceTo;
        });
        return ret;
    }

    getInputIndex(interfaceTo: FormInputInterfaceTo) {
        return this.inputs.indexOf(interfaceTo);
    }

    getFormValues() {
        const ret: FormValues = {};
        this.inputs.forEach(({getName,getValue}) => {
            const name = getName();
            let value = getValue();            
            ret[name] = value;
        });
        return ret;
    }

    clearInputErrors() {
        this.inputs.forEach(({getErrorState}) => {
            getErrorState().value = false;
        });
    }

    registerInput(interfaceTo: FormInputInterfaceTo): FormInputInterfaceFrom {
        this.inputs.push(interfaceTo);

        const signalKeyDownEnter = () => {
            const index = this.getInputIndex(interfaceTo);
            const moveToNextIndex = (index: number) => {
                if (index!==-1) {
                    if ((this.submit) && (!this.submit.getDisabledState().value)) {
                        const nextIndex = index+1;
                        // this.inputs.forEach(({getName}) => console.log(getName()));
                        // console.log(nextIndex,this.inputs.length);
                        if (nextIndex<this.inputs.length) {
                            if (this.inputs[nextIndex].getIsHidden()) {
                                moveToNextIndex(nextIndex);
                            }
                            else {
                                this.inputs[nextIndex].focus();
                            }                            
                        }
                        else {
                            this.formStateValue.submit();
                        }
                    }
                    else {                 
                        
                        const nextIndex = (index+1)%this.inputs.length;
                        // console.log("nextIndex",nextIndex);       
                        if (nextIndex!==index) {
                            if (this.inputs[nextIndex].getIsHidden()) {
                                moveToNextIndex(nextIndex);
                            }
                            else {
                                this.inputs[nextIndex].focus();
                            }                            
                        }                    
                    }
                }    
            };
            moveToNextIndex(index);
        };

        const signalChange = () => {
            this.formStateValue.clearError();
            this.updateSubmitDisabledBasedOnDisabledIfOtpNotComplete();
        };

        const name = interfaceTo.getName();

        const makeup = this.getMakeupByName(name);

        if (!makeup) throw new Error(`could not find input '${name}' in form request makeup`);

        const signalDiffersFromInitialValueChange = () => {
            this.updateSubmitDisabledBasedOnDisabledIfChanged();
        };

        const toCallOnFirstEffect = this.valuesInitialized?() => {
            this.setSingleInputInitialValues({},interfaceTo);
        }:undefined;
    
        return { signalKeyDownEnter,signalChange,makeup,signalDiffersFromInitialValueChange,toCallOnFirstEffect };
    }

    unregisterInput(interfaceTo: FormInputInterfaceTo) {
        const newRegisteredInputs = this.inputs.filter(x => x!==interfaceTo);
        this.inputs.length = 0;
        this.inputs.push(...newRegisteredInputs);
    }

    registerSubmit(interfaceTo: FormSubmitInterfaceTo,name?: string) {        
        if (name) {
            const alternativeSubmit = (this.formRequestMakeup.alternativeSubmits || {})[name];
            if (!alternativeSubmit) throw new Error(`no alternative submit exists called '${name}'`);
            this.namedSubmits[name] = interfaceTo;
        }
        else {
            this.submit = interfaceTo;
        }        
    }

    unregisterSubmit(interfaceTo: FormSubmitInterfaceTo,name?: string) {
        if (name) {
            if (this.namedSubmits[name]===interfaceTo) {
                delete this.namedSubmits[name];
            }    
        }
        else {
            if (this.submit===interfaceTo) {
                this.submit = null;
            }    
        }
    }

    getMakeupByName(name: string) {
        const makeup = this.formRequestMakeup.inputs[name];
        if (!makeup) throw new Error(`could not find input '${name}' in form request makeup`);
        return makeup;
    }

    updateInputValues(updateValues: FormValues) {
        const inputHash = this.getInputHashByName();
        Object.keys(updateValues).forEach((name) => {
            const input = inputHash[name];
            if (input) input.setValue(updateValues[name]);
        });
    }

    disableForm(value = true) {
        this.inputs.forEach(({getDisabledState}) => {
            getDisabledState().value = value;
        });
        if (this.submit) {
            this.submit.getDisabledState().value = value;
        }
    }

    getInputDiffersFromInitialValueTable() {
        const ret: {[name: string]: boolean} = {};
        this.inputs.forEach(({getName,getDiffersFromInitialValue}) => {
            ret[getName()] = getDiffersFromInitialValue();
        });
        return ret;
    }

    updateSubmitDisabledBasedOnDisabledIfChanged() {
        if (this.submit) {
            const names = this.submit.getDisableIfUnchanged();
            if (names.length>0) {
                const table = this.getInputDiffersFromInitialValueTable();
                this.submit.getDisabledState().value = (() => {                
                    return (names.filter((name) => table[name]).length===0);
                })();                    
            }
        }
    }

    updateSubmitDisabledBasedOnDisabledIfOtpNotComplete() {
        if (this.submit) {
            const inputHash = this.getInputHashByName();
            const otpName = this.submit.getDisableIfOtpNotComplete();
            if (inputHash[otpName]) {
                this.submit.getDisabledState().value = !inputHash[otpName].getOtpIsComplete();
            }
        }
    }

    setSingleInputInitialValues(overrideInputInitialValues: {[name: string]: string},{getName,getInitialValue,setValue,signalInitialized,getType,getInitialSelectedIndex,setSelectedIndex}: FormInputInterfaceTo) {
        const name = getName();  
        const initialSelectedIndex = getInitialSelectedIndex();          
        if ((getType()==="select") && (initialSelectedIndex>=0)) {
            setSelectedIndex(initialSelectedIndex);
        }
        else {
            const initialValue = (typeof overrideInputInitialValues[name]==="string")?overrideInputInitialValues[name]:getInitialValue();
            const defaultInitialValue = this.formRequestMakeup.inputs[name]?.defaultInitialValue || "";
            setValue(initialValue || defaultInitialValue);
        }            
        signalInitialized();
    }

    setInitialValues(overrideInputInitialValues: {[name: string]: string}) {
        this.inputs.forEach((input) => {
            this.setSingleInputInitialValues(overrideInputInitialValues,input);
        });
        this.valuesInitialized = true;
    }

    focusInput(name: string) {
        const input = this.getInputByName(name);
        if (input) {
            const { focus } = input;
            focus();
        }
    }

    setInputValue(name: string,value: string) {
        const input = this.getInputByName(name);
        if (input) {
            const { focus,setValue } = input;
            setValue(value);
        }
    }
}

export type FormInputInterfaceTo = {
    focus: () => void;
    getName: () => string;
    getValue: () => string;
    setValue: (value: string) => void;
    setSelectedIndex: (value: number) => void;
    signalInitialized: () => void;
    getErrorState: () => SmartState<boolean>;
    getInitialValue: () => string;
    getInitialSelectedIndex: () => number;
    getIsHidden: () => boolean;
    getType: () => string;
    getDisabledState: () => SmartState<boolean>;
    getDiffersFromInitialValue: () => boolean;
    getOtpIsComplete: () => boolean;
};
export type FormInputInterfaceFrom = {
    signalKeyDownEnter: () => void;
    signalChange: () => void;
    signalDiffersFromInitialValueChange: () => void;
    makeup: FormRequestMakeupInput;
    toCallOnFirstEffect?: () => void;
};

export type FormSubmitInterfaceTo = {
    getDisabledState: () => SmartState<boolean>;
    getDisableIfUnchanged: () => string[];
    getDisableIfOtpNotComplete: () => string;
};

export type FormRefInterface = {
    submit: (name?: string) => void;
};

function _Form({
        children = null,
        parent,
        input,
        autoFocus,
        requestMakeup = { inputs: {},relativeUrl: "" },
        onSuccess = () => {},
        align = "left",
        initializeCb = () => {},
        initialError = "",
        invisible = false,
        alternateStatusMessage
    }:FormProps,ref: MutableRefObject<FormRefInterface>) {
    const cssStateManager = useCssStateManager(["form"]);
    cssStateManager.useProperty(invisible,"is-invisible");

    const formStateValueRef = useRef<FormState>();

    const statusMessage = alternateStatusMessage || useStatusMessage(invisible);

    const isSecurePage = useIsSecurePage();

    const account = consumeAccount();
    const router = useCustomRouter();

    if (parent) {
        useAccountBarParentNav(parent.caption,parent.path);
    }

    formStateValueRef.current = formStateValueRef.current || { 
        align,
        getFormValues: () => {
            const { current: internalData } = internalDataRef;
            return internalData.getFormValues();
        },
        renderParentNav: (cb) => {
            if (parent) {
                return cb(parent);
            }
            else {
                return null;
            }
        },
        input: input || {},
        useInput: (define) => {
            const interfaceFromRef = useRef<FormInputInterfaceFrom>(null);
            const interfaceToRef = useRef<FormInputInterfaceTo>(null);
            const isFirstRender = useRef(true);
            
            if (isFirstRender.current) {
                const { current: internalData } = internalDataRef;
                interfaceToRef.current = define();
                interfaceFromRef.current = internalData.registerInput(interfaceToRef.current);
            }

            isFirstRender.current = false;

            useEffect(() => {                
                // const { getInitialValue,setValue } = interfaceToRef.current;
                // setValue(getInitialValue());
                return () => {
                    const { current: internalData } = internalDataRef;
                    internalData.unregisterInput(interfaceToRef.current);
                };
            },[]);

            return interfaceFromRef.current;
        },
        focusInput(name: string) {
            const { current: internalData } = internalDataRef;
            internalData.focusInput(name);
        },
        setInputValue(name: string,value: string) {
            const { current: internalData } = internalDataRef;
            internalData.setInputValue(name,value);
        },
        useSubmit: (define,name) => {
            const interfaceToRef = useRef<FormSubmitInterfaceTo>(null);
            const isFirstRender = useRef(true);
            
            if (isFirstRender.current) {
                const { current: internalData } = internalDataRef;
                interfaceToRef.current = define();
                internalData.registerSubmit(interfaceToRef.current,name);
            }

            isFirstRender.current = false;

            useEffect(() => {
                return () => {
                    const { current: internalData } = internalDataRef;
                    internalData.unregisterSubmit(interfaceToRef.current,name);
                };
            },[]);
        },
        async submit(name?: string) {
            alert("Demo: this feature has been disabled");
            return;
            let { relativeUrl } = requestMakeup;
            let emptyForm = false;
            if (name) {
                const alternativeSubmit = (requestMakeup.alternativeSubmits || {})[name];
                if (alternativeSubmit) {
                    relativeUrl = alternativeSubmit.relativeUrl;
                    emptyForm = !!alternativeSubmit.emptyForm;
                }
            }

            formStateValueRef.current.clearError();
            const { current: internalData } = internalDataRef;
            let values = internalData.getFormValues();     
            const { values: _values,error,updateValues } = preprocessFromMakeup(values,requestMakeup,"client",emptyForm);
            values = _values;
            internalData.updateInputValues(updateValues);

            const processError = (error: FormError) => {
                const { message,inputNames } = error;
                const errorTable = requestMakeup.errorTable || {};
                statusMessage.showError(errorTable[message] || message);
                const inputHash = internalData.getInputHashByName();
                let firstInput: FormInputInterfaceTo;
                inputNames.forEach((name) => {
                    const input = inputHash[name as string];
                    if (input) {
                        input.getErrorState().value = true;
                        if (!firstInput) firstInput = input;
                    }
                });
                firstInput = firstInput || internalData.getFirstNonHiddenInput();
                if (firstInput) {
                    firstInput.focus();
                }
            };

            if (error) {
                processError(error);
            }
            else {
                let success = false;
                let result: any;
                await router.runLocked(async () => {
                    internalData.disableForm();
                    try {                            
                        const { result: _result,newAccountValue } = await postRequest(isSecurePage?account.value as Account:null,relativeUrl,values);
                        if ((isSecurePage) && (newAccountValue)) {
                            account.value = newAccountValue;
                        }
                        success = true;
                        result = _result;
                    }
                    catch(err) {
                        if ((isSecurePage) && (err instanceof RequestErrorPermissionDenied)) {
                            account.value = null;
                            router.push("/login");
                            return;
                        }
                        else if (err instanceof RequestErrorNoNetworkConnection) {                            
                            statusMessage.showError("Network error: make sure you are connected to the internet.");
                            internalData.disableForm(false);
                        }
                        else if (err instanceof RequestErrorCustom) {
                            internalData.disableForm(false);
                            const { message,inputNames } = err;
                            processError({
                                message,
                                inputNames
                            });
                        }
                        else {
                            statusMessage.showError("An unexpected error occurred, please try again. If this persists, contact us");
                            internalData.disableForm(false);
                        }                            
                    }    
                });
                // if (success) {
                //     const res = onSuccess(result,name);
                //     if (res) {
                //         if (res.type==="redirect") {
                //             if (res.message) statusMessage.loadMessage(res.message);
                //             if (misc.isExternalPath(res.path)) {
                //                 location.href = res.path;
                //             }
                //             else {
                //                 router.push(res.path);
                //             }                            
                //         }
                //         else if (res.type==="message") {
                //             internalData.disableForm(false);
                //             statusMessage.showFromObject(res.message);
                //         }
                //     }
                //     else {
                //         internalData.disableForm(false);
                //     }
                // }
            }
        },
        clearError() {
            statusMessage.close();
            const { current: internalData } = internalDataRef;
            internalData.clearInputErrors();
        }
    };

    useImperativeHandle(ref,() => ({
        submit(name?: string) {
            formStateValueRef.current.submit(name);
        }
    }));

    const internalDataRef = useRef<FormInternalData>(null);
    internalDataRef.current = internalDataRef.current || new FormInternalData(formStateValueRef.current,requestMakeup);

    useEffect(() => {
        const res = initializeCb();
        const { current: internalData } = internalDataRef;        
        internalData.setInitialValues((res && res.inputInitialValues)?res.inputInitialValues:{});
        if ((res) && (res.autoFocus)) {
            autoFocus = res.autoFocus;
        }
        if (res && res.message) {
            res.message = (typeof res.message==="string")?{value: res.message}:res.message;            
            statusMessage.showInfo(res.message.value,!!res.message.permanent);
        }
        else if ((typeof initialError==="string") && (initialError!=="")) {
            const errorTable = requestMakeup.errorTable || {};            
            statusMessage.showError(errorTable[initialError] || initialError);
        }

        const firstInput = internalData.getFirstNonHiddenInput();
        if ((autoFocus) && (firstInput)) {
            const input = (typeof autoFocus==="string")?internalData.getInputByName(autoFocus):firstInput;
            if (input) input.focus();
        }
        internalData.updateSubmitDisabledBasedOnDisabledIfChanged();
        internalData.updateSubmitDisabledBasedOnDisabledIfOtpNotComplete();
    },[]);

    return (
        <>
            <div className={cssStateManager.getClassNames()}>                
                {invisible?null:statusMessage.component}
                <FormStateContext.Provider value={formStateValueRef.current}>
                    {children}
                </FormStateContext.Provider>                
            </div>
            <style jsx>{`
            .form {
                --text-color: #555;
                --border-color: #ccc;
                --input-backcolor: #fff;
                --input-textcolor: #666;
                --input-placeholder-textcolor: #ccc;
                --input-bordercolor: #666;
                --input-focus-bordercolor: #908ee9;
                --input-disabled-backcolor: #f9f9f9;
                --error-color: #dc0303;
                --button-backcolor: #076bd1;
                --button-textcolor: #fff;
                --button-alternate-backcolor: #555;
                --button-alternate-textcolor: #fff;
            }

            .is-invisible {
                position: absolute;
                visibility: hidden;
            }
            `}</style>
        </>
    );
}

export type FormState = {
    renderParentNav: (cb: (parent: FormParent) => any) => any;
    input: FormInput;
    align: FormAlignment;
    useInput: (define: () => FormInputInterfaceTo) => FormInputInterfaceFrom;
    useSubmit: (define: () => FormSubmitInterfaceTo,name?: string) => void;
    submit: (name?: string) => Promise<void>;
    clearError: () => void;
    getFormValues: () => FormValues;
    focusInput: (name: string) => void;
    setInputValue: (name: string,value: string) => void;
};

export const FormStateContext = createContext<FormState>(null);

export const useForm = () => {
    const ret = useContext(FormStateContext);
    if (!ret) throw new Error("useForm cannot be used within a component that is not a child of a Form component");
    return ret;
};

const Form = forwardRef(_Form);

export default Form;