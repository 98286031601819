import { SelectOption } from "../types/form.types";
import rawCountryMakeup from "../shopify-country-makeup-with-code.min.json";
import { postcodeValidator,postcodeValidatorExistsForCountry } from "postcode-validator";
import { findBestMatch } from "string-similarity";

export const country3isoTo2isoTable = {
    "AFG": "AF",
    "ALB": "AL",
    "DZA": "DZ",
    "ASM": "AS",
    "AND": "AD",
    "AGO": "AO",
    "AIA": "AI",
    "ATA": "AQ",
    "ATG": "AG",
    "ARG": "AR",
    "ARM": "AM",
    "ABW": "AW",
    "AUS": "AU",
    "AUT": "AT",
    "AZE": "AZ",
    "BHS": "BS",
    "BHR": "BH",
    "BGD": "BD",
    "BRB": "BB",
    "BLR": "BY",
    "BEL": "BE",
    "BLZ": "BZ",
    "BEN": "BJ",
    "BMU": "BM",
    "BTN": "BT",
    "BOL": "BO",
    "BES": "BQ",
    "BIH": "BA",
    "BWA": "BW",
    "BVT": "BV",
    "BRA": "BR",
    "IOT": "IO",
    "BRN": "BN",
    "BGR": "BG",
    "BFA": "BF",
    "BDI": "BI",
    "CPV": "CV",
    "KHM": "KH",
    "CMR": "CM",
    "CAN": "CA",
    "CYM": "KY",
    "CAF": "CF",
    "TCD": "TD",
    "CHL": "CL",
    "CHN": "CN",
    "CXR": "CX",
    "CCK": "CC",
    "COL": "CO",
    "COM": "KM",
    "COD": "CD",
    "COG": "CG",
    "COK": "CK",
    "CRI": "CR",
    "HRV": "HR",
    "CUB": "CU",
    "CUW": "CW",
    "CYP": "CY",
    "CZE": "CZ",
    "CIV": "CI",
    "DNK": "DK",
    "DJI": "DJ",
    "DMA": "DM",
    "DOM": "DO",
    "ECU": "EC",
    "EGY": "EG",
    "SLV": "SV",
    "GNQ": "GQ",
    "ERI": "ER",
    "EST": "EE",
    "SWZ": "SZ",
    "ETH": "ET",
    "FLK": "FK",
    "FRO": "FO",
    "FJI": "FJ",
    "FIN": "FI",
    "FRA": "FR",
    "GUF": "GF",
    "PYF": "PF",
    "ATF": "TF",
    "GAB": "GA",
    "GMB": "GM",
    "GEO": "GE",
    "DEU": "DE",
    "GHA": "GH",
    "GIB": "GI",
    "GRC": "GR",
    "GRL": "GL",
    "GRD": "GD",
    "GLP": "GP",
    "GUM": "GU",
    "GTM": "GT",
    "GGY": "GG",
    "GIN": "GN",
    "GNB": "GW",
    "GUY": "GY",
    "HTI": "HT",
    "HMD": "HM",
    "VAT": "VA",
    "HND": "HN",
    "HKG": "HK",
    "HUN": "HU",
    "ISL": "IS",
    "IND": "IN",
    "IDN": "ID",
    "IRN": "IR",
    "IRQ": "IQ",
    "IRL": "IE",
    "IMN": "IM",
    "ISR": "IL",
    "ITA": "IT",
    "JAM": "JM",
    "JPN": "JP",
    "JEY": "JE",
    "JOR": "JO",
    "KAZ": "KZ",
    "KEN": "KE",
    "KIR": "KI",
    "PRK": "KP",
    "KOR": "KR",
    "KWT": "KW",
    "KGZ": "KG",
    "LAO": "LA",
    "LVA": "LV",
    "LBN": "LB",
    "LSO": "LS",
    "LBR": "LR",
    "LBY": "LY",
    "LIE": "LI",
    "LTU": "LT",
    "LUX": "LU",
    "MAC": "MO",
    "MDG": "MG",
    "MWI": "MW",
    "MYS": "MY",
    "MDV": "MV",
    "MLI": "ML",
    "MLT": "MT",
    "MHL": "MH",
    "MTQ": "MQ",
    "MRT": "MR",
    "MUS": "MU",
    "MYT": "YT",
    "MEX": "MX",
    "FSM": "FM",
    "MDA": "MD",
    "MCO": "MC",
    "MNG": "MN",
    "MNE": "ME",
    "MSR": "MS",
    "MAR": "MA",
    "MOZ": "MZ",
    "MMR": "MM",
    "NAM": "NA",
    "NRU": "NR",
    "NPL": "NP",
    "NLD": "NL",
    "NCL": "NC",
    "NZL": "NZ",
    "NIC": "NI",
    "NER": "NE",
    "NGA": "NG",
    "NIU": "NU",
    "NFK": "NF",
    "MNP": "MP",
    "NOR": "NO",
    "OMN": "OM",
    "PAK": "PK",
    "PLW": "PW",
    "PSE": "PS",
    "PAN": "PA",
    "PNG": "PG",
    "PRY": "PY",
    "PER": "PE",
    "PHL": "PH",
    "PCN": "PN",
    "POL": "PL",
    "PRT": "PT",
    "PRI": "PR",
    "QAT": "QA",
    "MKD": "MK",
    "ROU": "RO",
    "RUS": "RU",
    "RWA": "RW",
    "REU": "RE",
    "BLM": "BL",
    "SHN": "SH",
    "KNA": "KN",
    "LCA": "LC",
    "MAF": "MF",
    "SPM": "PM",
    "VCT": "VC",
    "WSM": "WS",
    "SMR": "SM",
    "STP": "ST",
    "SAU": "SA",
    "SEN": "SN",
    "SRB": "RS",
    "SYC": "SC",
    "SLE": "SL",
    "SGP": "SG",
    "SXM": "SX",
    "SVK": "SK",
    "SVN": "SI",
    "SLB": "SB",
    "SOM": "SO",
    "ZAF": "ZA",
    "SGS": "GS",
    "SSD": "SS",
    "ESP": "ES",
    "LKA": "LK",
    "SDN": "SD",
    "SUR": "SR",
    "SJM": "SJ",
    "SWE": "SE",
    "CHE": "CH",
    "SYR": "SY",
    "TWN": "TW",
    "TJK": "TJ",
    "TZA": "TZ",
    "THA": "TH",
    "TLS": "TL",
    "TGO": "TG",
    "TKL": "TK",
    "TON": "TO",
    "TTO": "TT",
    "TUN": "TN",
    "TUR": "TR",
    "TKM": "TM",
    "TCA": "TC",
    "TUV": "TV",
    "UGA": "UG",
    "UKR": "UA",
    "ARE": "AE",
    "GBR": "GB",
    "UMI": "UM",
    "USA": "US",
    "URY": "UY",
    "UZB": "UZ",
    "VUT": "VU",
    "VEN": "VE",
    "VNM": "VN",
    "VGB": "VG",
    "VIR": "VI",
    "WLF": "WF",
    "ESH": "EH",
    "YEM": "YE",
    "ZMB": "ZM",
    "ZWE": "ZW",
    "ALA": "AX"
  };

type RawCountryEntry = typeof rawCountryMakeup.countries[0];

export type CountryMakeupField = {
    id: string;
    caption: string;
    optional?: boolean;
};

export type CountryMakeupGroup = CountryMakeupField[];

export type CountryMakeup = CountryMakeupGroup[];

export type ProvinceOption = {
    id: string;
    caption: string;
};

export type Country = {
    value: string;
    caption: string;
    code: string;
    ccode: string;
    makeup: CountryMakeup;
    provinceOptions?: ProvinceOption[];
};

class CountryMakeupLib {
    private countryHash: {[value: string]: Country} = {};
    private cachedSelectOptions: SelectOption[];

    constructor() {
        const { mtable,countries } = rawCountryMakeup;
        countries.forEach(({caption,code,makeup,value,ccode,po}) => {
            this.countryHash[value] = {
                caption,
                code,
                ccode,
                makeup: mtable[makeup],
                value,
                ...(po?{provinceOptions: po}:{})
            };
        });
    }

    getCountry(value: string) {
        return this.countryHash[value];
    }

    getCountryCode(value: string) {
        return this.getCountry(value)?.ccode || "";
    }

    matchProvince(countryValue: string,provinceCaption: string) {
        const country = this.getCountry(countryValue);
        if ((country) && (country.provinceOptions)) {
            const { bestMatchIndex } = findBestMatch(provinceCaption,country.provinceOptions.map(({caption}) => caption));
            return country.provinceOptions[bestMatchIndex].id;
        }
        else {
            return "---";
        }
    }

    getProvinceOptionsHash(country: Country) {
        const ret: {[id: string]: string} = {};
        (country.provinceOptions || []).forEach(({id,caption}) => {
            ret[id] = caption;
        });
        return ret;
    }

    getCountryMakeupHash(value: string) {
        const country = this.getCountry(value);
        if (country) {
            const ret: {[id: string]: {
                caption: string;
                optional: boolean;
            }} = {};
            country.makeup.forEach((group) => {
                group.forEach(({id,caption,optional}) => {
                    ret[id] = {
                        caption,
                        optional: !!optional
                    };
                });
            });
            return ret;
        }
        else {
            return null;
        }
    }

    getSelectOptions() {
        const process = () => {
            const { countries } = rawCountryMakeup;
            const ret: SelectOption[] = [];
    
            const countryToSelectOption = ({value,caption}: RawCountryEntry) => {
                const ret: SelectOption = {
                    value,caption
                };
                return ret;
            };
    
            const topOptions = [
                "United States",
                "Canada",
                "Japan",
                "Australia"
            ];
            const countryHash: {[value: string]: RawCountryEntry} = {};
            countries.forEach((country) => {
                const { value } = country;
                countryHash[value] = country;
            });
            ret.push(...topOptions.map(x => countryHash[x]).map(countryToSelectOption));        
            ret.push({value: "---",caption: "---",disabled: true});
            ret.push(...countries.map(countryToSelectOption));
            return ret;
        };
        return (this.cachedSelectOptions = this.cachedSelectOptions || process())
    }

    validatePostCode(countryValue: string,postcodeValue: string) {
        const country = this.getCountry(countryValue);
        if (country) {
            const c2 = country3isoTo2isoTable[country.code];
            if ((c2) && (postcodeValidatorExistsForCountry(c2))) {
                return postcodeValidator(postcodeValue,c2);
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }
}

export const libCountryMakeup = new CountryMakeupLib();